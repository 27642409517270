//PROD configs
export const apiBaseUrl = "https://app.playartesanato.com";
//DEV configs
// export const apiBaseUrl = "http://127.0.0.1:8000";

//SPACE ID
// 1 - Play Artesanato
// 2 - Educadores Cristãos
// 3 - Oficina do Educador
export const spaceId = 2;
//Appearance
export const showSeal = false;

export const localUrl = window.location.protocol + "//" + window.location.host;
export const basename = "./";
export const storageKey = "@g3members" + spaceId;
export const version = "V2308251854";
export const apiVersion = "v1";
export const apiPrefix = "api";
